<script lang="ts">
  import type { Property } from "../types";
  import { thumbnail } from "../util/filepreview";
  export let property: Property;
</script>

<header class="welcome">
  <h1>
    Welcome to <data class="property" value={property.id}>{property.name}</data>
    <address>
      <data class="city">{property.address.city}</data>
      <data class="state">{property.address.state}</data>
    </address>
  </h1>
  <!-- <img alt="{property.name} photo" src={thumbnail(property.photo, 800, 400)} /> -->
</header>
