<script lang="ts" context="module">
  const level = param("level");
</script>

<script lang="ts">
  import type { Property, Unit } from "../../types";
  import { hover } from "../../util/behaviorstores";
  import { param } from "../../util/params";
  import { directory, units } from "../../util/propertystores";
  import { query } from "../../util/router";
  import FeaturePoint from "../FeaturePoint.svelte";
  import MapFeatureClick from "../map/MapFeatureClick.svelte";
  import MapFeatureHover from "../map/MapFeatureHover.svelte";
  import MapFeatureState from "../map/MapFeatureState.svelte";
  import MapMarker from "../map/MapMarker.svelte";
  import type { Feature } from "../map/mapping";
  import PropertyLevelSelect from "../PropertyLevelSelect.svelte";
  import PropertyMap from "../PropertyMap.svelte";
  import RecordItem from "../RecordItem.svelte";
  export let property: Property;
  export let referrer: string = null;
  export let unit: Unit = null;

  $: console.log("units=", $units);

  let levels;
</script>

<main class="map">
  <header>
    <h1>
      <data class="property" value={property.id}>{property.name}</data>
      <address>
        <data class="city">{property.address.city}</data>
        <data class="state">{property.address.state}</data>
      </address>
    </h1>
    {#if referrer}
      <a href={referrer}>back</a>
    {/if}
  </header>
  <PropertyLevelSelect
    level={$level}
    {levels}
    on:change={(e) => query("level", e.detail)}
  />
  <PropertyMap
    property={property.id}
    level={$level}
    bind:levels
    selected={unit?.id}
  >
    <MapFeatureState
      state={(feature) =>
        $units?.[feature?.properties["ref:boss:subject"]] && {
          selectable: true,
          //selected: unit && unit.id == feature.properties["ref:boss:subject"],
        }}
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["has", "ref:boss:subject"],
      ]}
    />
    {#if $hover}
      <MapFeatureHover
        filter={[
          "all",
          ["==", ["geometry-type"], "Polygon"],
          ["has", "ref:boss:subject"],
        ]}
      />
    {/if}
    <MapFeatureClick
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["has", "ref:boss:subject"],
      ]}
      on:click={(e) => {
        console.log("map click=", e);
        query("selected", e.detail.properties["ref:boss:subject"]);
      }}
    />
  </PropertyMap>
  {#if unit}
    <section>
      <header>
        <RecordItem item={unit} /><button
          type="button"
          on:click={(e) => query("selected", null)}>change</button
        >
      </header>
    </section>
  {/if}
  <nav>
    <ul class="items">
      {#each Object.values($units ?? []) as item}
        <li>
          <a
            class="record"
            href="{referrer}/units?level={item.level}&selected={item.id}"
            ><RecordItem {item} /></a
          >
        </li>
      {/each}
    </ul>
  </nav>
  <slot />
</main>
