<script lang="ts" context="module">
  const selected = param("selected");
</script>

<script lang="ts">
  import type { Property } from "../../types";
  import { param } from "../../util/params";
  import { directory, units } from "../../util/propertystores";
  import { query } from "../../util/router";
  import { view } from "../../util/uistores";
  import FeatureList from "../FeatureList.svelte";
  import FeaturePoint from "../FeaturePoint.svelte";
  import Loading from "../Loading.svelte";
  import Logo from "../Logo.svelte";
  import PropertyMapPreview from "../PropertyMapPreview.svelte";
  import RecordItem from "../RecordItem.svelte";
  import Welcome from "../Welcome.svelte";
  import PropertyMapSection from "./PropertyMapSection.svelte";
  import PropertyUnitSection from "./PropertyUnitSection.svelte";

  export let property: Property = null;
  $: feature = $directory?.features.find((f) => f.properties.id === $selected);

  $: base = `/properties/${property.id}`;

  $: if (selected || !$selected) scrollTo(0, 0);
</script>

<svelte:head>
  <title>Welcome to {property.name}</title>
</svelte:head>

{#if "map" === $view}
  <PropertyMapSection {property} referrer={base} {feature} />
{:else if "units" === $view}
  <PropertyUnitSection {property} referrer={base} unit={$units?.[$selected]} />
{:else}
  <Welcome {property} />
  <nav>
    <ul>
      <li>
        <a href="{base}/units">Unit Directory</a>
      </li>
    </ul>
  </nav>
  <section>
    <PropertyMapPreview {property}>
      <nav>
        <ul>
          <!-- <li>
            <a href="{base}/units">Unit Directory</a>
          </li> -->
          <li><a href="{base}/map">Explore the Map</a></li>
        </ul>
      </nav>
    </PropertyMapPreview>
    <!-- <nav>
      <ul><li><a href="{base}/units">Unit Directory</a></li></ul>
      <ul><li><a href="{base}/map">Directory</a></li></ul>
    </nav> -->
    <!-- {#if $directory}
      <FeatureList features={$directory} url={base + "/map"} />
    {:else}
      <Loading />
    {/if} -->
  </section>

  <footer>
    <Logo {property} />
  </footer>
{/if}
