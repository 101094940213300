<script lang="ts">
  import type { Addressable } from "../types";
  export let item: Addressable;
</script>

<address>
  <data class="street">{item["addr:housenumber"]} {item["addr:street"]}</data>
  <data class="city">{item["addr:city"]}</data>
  <data class="state">{item["addr:state"]}</data>
  <data class="postcode">{item["addr:postcode"]}</data>
</address>
