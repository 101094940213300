<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { ByLevel, ByLevels } from "../types";
  import { sortBy } from "../util/sort";
  import Loading from "./Loading.svelte";
  import RecordItem from "./RecordItem.svelte";

  //const dispatchEvent = createEventDispatcher();
  export let base: string;
  export let level: string;
  export let availability: ByLevels;
  let byLevel: ByLevel;
  $: byLevel = availability?.[level];

  // function recordclick(e: Event) {
  //   const button = e.currentTarget as HTMLButtonElement;
  //   dispatchEvent("change", {
  //     [button.name]: button.value,
  //   });
  // }
</script>

<nav>
  {#if !availability}
    <Loading message="Loading available spaces" />
  {:else if byLevel}
    <header>
      <h1>Available Spaces</h1>
    </header>
    <ul class="items">
      {#each Object.values(byLevel.items) as item}
        <li>
          <a class="record" href="{base}?level={item.level}&selected={item.id}"
            ><RecordItem {item} /></a
          >
        </li>
      {/each}
    </ul>
  {/if}
</nav>
<!-- form-y stuff -->
