<script lang="ts" context="module">
  const level = param("level");
</script>

<script lang="ts">
  import type { Property } from "../../types";
  import { param } from "../../util/params";
  import { directory } from "../../util/propertystores";
  import { query } from "../../util/router";
  import FeaturePoint from "../FeaturePoint.svelte";
  import MapMarker from "../map/MapMarker.svelte";
  import type { Feature } from "../map/mapping";
  import PropertyLevelSelect from "../PropertyLevelSelect.svelte";
  import PropertyMap from "../PropertyMap.svelte";
  export let property: Property;
  export let feature: Feature = null;
  export let referrer: string = null;

  let levels;
</script>

<main class="map">
  <header>
    <h1>
      <data class="property" value={property.id}>{property.name}</data>
      <address>
        <data class="city">{property.address.city}</data>
        <data class="state">{property.address.state}</data>
      </address>
    </h1>
    {#if referrer}
      <a href={referrer}>back</a>
    {/if}
  </header>
  <PropertyLevelSelect
    level={$level}
    {levels}
    on:change={(e) => query("level", e.detail)}
  />
  <PropertyMap property={property.id} level={$level} bind:levels>
    {#each $directory?.features ?? [] as feature}
      <MapMarker {feature}
        ><a
          href="{location.pathname}?level={(feature.properties.level ?? '')
            .toString()
            .split(';')[0] ?? ''}&selected={feature.properties.id}"
          ><FeaturePoint {feature} /></a
        ></MapMarker
      >
    {/each}
    {#if feature}
      <MapMarker class="selected" {feature}
        ><FeaturePoint {feature}>{feature.properties.name || ""}</FeaturePoint
        ></MapMarker
      >
    {/if}
  </PropertyMap>
  {#if feature}
    <section>
      <header>
        <FeaturePoint class="level" {feature}
          >{feature.properties.name || ""}</FeaturePoint
        >
      </header>
    </section>
  {/if}
  <slot />
</main>
