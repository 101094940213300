<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const eventing = createEventDispatcher();

  export let levels: { [key: string]: any } = {};
  export let level: string;
  $: console.log("level=", level);

  $: levelslist = Object.values(levels ?? {})
    .map((v: any) => ({
      ...v,
      value: v.level,
      ref: v["level:ref"],
    }))
    //.filter((i) => "" !== i.level)
    .sort(
      (a: any, b: any) =>
        nandor(parseFloat(a.level), -1000) - nandor(parseFloat(b.level), -1000)
    );
  function nandor(value, fallback) {
    return isNaN(value) ? fallback : value;
  }
  function label(ref: string) {
    if (ref === "Outdoors") return "Outside";
    return ref;
  }
  //$: if (null != level) eventing("change", level);
</script>

<select
  name="level"
  bind:value={level}
  on:change={(e) => eventing("change", e.currentTarget.value)}
>
  {#each levelslist as level}
    <option value={level.value}>{label(level.ref)}</option>
  {:else}
    <option value="outside">Outside</option>
  {/each}
</select>
