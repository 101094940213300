<script lang="ts">
  import type { Feature } from "geojson";
  import type { Property, Typed } from "../types";
  import FeaturePoint from "./FeaturePoint.svelte";

  let feature: Feature;
  let classname: string = "";
  export let property: Property | string = null;
  export let selected: Typed | Feature | string = null;
  export let zoom: number = null;
  export let title: string = "";

  export { classname as class };

  $: feature = selected as Feature;

  $: url = ((property: string, selected?: string, zoom?: number) => {
    if (!property) return null;
    const url = new URL(
      "https://static.carto.communityboss.app/cdn-cgi/image/format=auto,width=600,height=450,fit=cover/"
    );
    url.searchParams.set("property", property);
    if (selected) url.searchParams.set("selected", selected);
    if (zoom) url.searchParams.set("zoom", zoom.toString());
    return url.toString();
  })(
    (selected as Typed)?.property?.id ||
      (selected as Typed)?.scope ||
      (selected as Feature)?.properties?.["ref:boss:property"] ||
      (property as Property)?.id ||
      (property as string),
    (selected as Feature)?.properties?.id ||
      (selected as Typed)?.id ||
      (selected as string),
    zoom
  );

  $: console.log("url=", url, property, selected);
</script>

<figure class="preview {classname}">
  <img alt={title} src={url || ""} />
  {#if "Point" == feature?.geometry?.type && feature?.properties}
    <button type="button"
      ><FeaturePoint {feature}>{feature.properties.name || ""}</FeaturePoint
      ></button
    >
  {/if}
  <figcaption>
    <slot />
  </figcaption>
</figure>
