<script lang="ts">
  import PropertySection from "./components/sections/PropertySection.svelte";
  import Loading from "./components/Loading.svelte";
  import {
    property,
    account,
    propertyId,
    accountId,
    unitId,
    unit,
    policyId,
    policy,
  } from "./util/propertystores";
  import AccountSection from "./components/sections/AccountSection.svelte";
  import PropertiesSection from "./components/sections/PropertiesSection.svelte";
  import UnitSection from "./components/sections/UnitSection.svelte";
  import PolicySection from "./components/sections/PolicySection.svelte";

  $: console.log("account=", $account);
</script>

{#if $account}
  <AccountSection account={$account} />
{:else if $unit}
  <UnitSection unit={$unit} />
{:else if $policy}
  <PolicySection policy={$policy} />
{:else if $property}
  <PropertySection property={$property} />
{:else if $propertyId || $accountId || $unitId || $policyId}
  <Loading />
{:else}
  <PropertiesSection />
{/if}
