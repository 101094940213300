import { derived, type Readable } from "svelte/store";
import {
  fetchDirectory,
  fetchPermitsForTenant,
  fetchPolicies,
  fetchPolicy,
  fetchProperty,
  fetchSpaceStatus,
  fetchTenant,
  fetchUnit,
  fetchUnits,
} from "./api";
import { param } from "./params";
import type {
  Property,
  Policy,
  Unit,
  Prices,
  Tenant,
  Permit,
  Space,
  ByLevels,
  Spaces,
} from "../types";
import type { FeatureCollection } from "geojson";
import { format, isToday, parseISO } from "date-fns";

export const accountId = param("account");
export const unitId = param("unit");
export const policyId = param("policy");

export const policy = derived<typeof policyId, Policy>(
  policyId,
  function updater($policyId, set) {
    if (!$policyId) return set(null);
    fetchPolicy($policyId).then(set);
  }
);

export const account = derived<Readable<string>, Tenant>(
  accountId,
  function updater($accountId, set) {
    if (!$accountId) return set(null);

    fetchTenant($accountId)
      .then(function (json) {
        return json.items[$accountId];
      })
      .then(set);
  }
);

export const accountPermits = derived<
  [typeof accountId],
  Record<string, Permit>
>([accountId], ([$accountId], set) => {
  if (!$accountId) return null;
  fetchPermitsForTenant($accountId)
    .then((json) => json.permits?.items)
    .then(set);
});

export const propertyId = derived<
  [Readable<string>, typeof account, typeof policy],
  string
>(
  [param("property"), account, policy],
  ([$id, $account, $policy]) =>
    $id || $account?.property?.id || $policy?.property.id
);

export const unit = derived<[typeof account, typeof unitId], Unit>(
  [account, unitId],
  function updater([$account, $unitId], set) {
    if ($account) return set($account.unit);
    if (!$unitId) return set(null);
    fetchUnit($unitId)
      .then(function (json) {
        return json.items[$unitId];
      })
      .then(set);
    // fetch unit
    //fetchProperty($propertyId).then(set);
  }
);

export const property = derived<[typeof account, typeof propertyId], Property>(
  [account, propertyId],
  function updater([$account, $propertyId], set) {
    if ($account) return set($account.property);
    if (!$propertyId) return set(null);
    fetchProperty($propertyId).then(set);
  }
);

export const units = derived<typeof propertyId, Record<string, Unit>>(
  propertyId,
  ($propertyId, set) => {
    if (!$propertyId) return set(null);

    fetchUnits($propertyId)
      .then(function (json) {
        const meta = json.units["for"][$propertyId];
        const items = meta.items;

        for (const [k, v] of Object.entries(items)) {
          items[k] = json.items[v as string] ?? json.items[k] ?? v;
        }

        return items;
      })
      .then(set);
  }
);

export const policies = derived<Readable<string>, Record<string, Unit>>(
  propertyId,
  ($propertyId, set) => {
    if (!$propertyId) return set(null);

    fetchPolicies($propertyId)
      .then((json) => json.policies.items)
      .then(set);
  }
);

export const directory = derived<
  [typeof propertyId, typeof account],
  FeatureCollection
>([propertyId, account], function updater([$propertyId, $account], set) {
  if (!$propertyId) return set(null);
  fetchDirectory($propertyId, $account?.unit.id).then(set);
});

export const valid = param("valid", true, format(new Date(), "yyyy-MM-dd"));

const status = derived(
  [propertyId, valid],
  function updater([$propertyId, $valid], set) {
    if (!$propertyId) return set(null);
    // console.log(
    //   "$valid=",
    //   $valid,
    //   parseISO($valid),
    //   format(new Date($valid), "yyyy-MM-dd'T'00:00:00'/'")
    // );
    set(null);
    fetchSpaceStatus(
      $propertyId,
      isToday(new Date($valid as string))
        ? null
        : format(parseISO($valid as string), "yyyy-MM-dd'T'00:00:00'/'")
    ).then(set);
  }
) as Readable<any>;

export const spaces = derived<typeof status, Record<string, Space>>(
  status,
  ($status, set) => {
    if (!$status) return set(null);

    set(
      Object.values($status.spaces.items).reduce(
        (result: Record<string, Space>, item: string | Space) => {
          item = ($status.items[item as string] as Space) || (item as Space);

          for (const k1 of ["prices", "permitted"]) {
            item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
          }

          result[item.id] = item;

          return result;
        },
        {}
      ) as Record<string, Space>
    );
    // for (const item of Object.values($policy?.spaces?.items ?? {}) as any[]) {
    //   for (const k1 of ["prices", "permitted"]) {
    //     item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
    //   }
  }
);

export const spacesByLevel = derived<[typeof policy, typeof status], ByLevels>(
  [policy, status],
  ([$policy, $status]) => {
    if (null == $policy || null == $status) return null;

    const levels: Record<string, any> = {
      // outside: {
      //   level: "outside",
      //   "level:ref": "Outside",
      //   count: 0,
      //   available: 0,
      // },
    };

    for (const item of Object.values($policy?.spaces?.items ?? {}) as any[]) {
      for (const k1 of ["prices", "permitted"]) {
        item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
      }

      for (let l of item.level?.split ? item.level.split(";") : [""]) {
        if ("" == l) l = "outside";
        if (!levels[l])
          levels[l] = {
            "level:ref": `Floor ${l}`,
            items: {},
            level: l,
            count: 0,
            available: 0,
          };
        levels[l].count++;
        if (item["level:ref"]) levels[l]["level:ref"] = item["level:ref"];
        if (false !== item.permitted?.available) {
          levels[l].items[item.id] = item;
          levels[l].available++;
        }
      }
    }

    return levels;
  }
);

export const availableSpaces = derived<[typeof policy, typeof status], Spaces>(
  [policy, status],
  ([$policy, $status], set) => {
    if (null == $policy || null == $status) return set(null);

    set(
      Object.values($policy?.spaces?.items ?? {}).reduce(
        (result: any, item: any) => {
          for (const k1 of ["prices", "permitted"]) {
            item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
          }
          if (false !== item.permitted?.available) {
            result.count++;
            result.items[item.id] = item;
          }
          return result;
        },
        {
          count: 0,
          items: {},
        }
      )
    );
  }
);
