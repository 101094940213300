<script lang="ts" context="module">
  const level = param("level");
  const selectedId = param("selected");
</script>

<script lang="ts">
  import { noop } from "svelte/internal";

  import type { Policy, Space } from "../../types";
  import { hover } from "../../util/behaviorstores";
  import { param } from "../../util/params";
  import {
    availableSpaces,
    spacesByLevel,
    units,
  } from "../../util/propertystores";
  import { query } from "../../util/router";
  import MapFeatureClick from "../map/MapFeatureClick.svelte";
  import MapFeatureHover from "../map/MapFeatureHover.svelte";
  import MapFeatureState from "../map/MapFeatureState.svelte";
  import ParkingAvailabilityLevels from "../ParkingAvailabilityLevels.svelte";
  import ParkingAvailabilitySpaces from "../ParkingAvailabilitySpaces.svelte";
  import PropertyMap from "../PropertyMap.svelte";
  import RecordItem from "../RecordItem.svelte";
  export let policy: Policy;
  export let referrer: string = null;
  export let space: Space = null;
  export let base: string;

  const filter = [
    "all",
    ["==", ["geometry-type"], "Polygon"],
    ["==", ["get", "amenity"], "parking_space"],
    ["has", "ref:boss:subject"],
  ];

  let property = policy.property;

  let levels;

  $: availability = $spacesByLevel;

  // $: allLevels = sortBy(
  //   Object.entries(availability ?? {}),
  //   ([level]) => level
  // ).map(([spacelevel, item]) => item);

  $: levelsWithAvailablity = Object.values(availability ?? {}).filter(
    (item) => item.available
  );

  // change level if necessary
  $: if (
    availability &&
    !availability?.[$level] &&
    !space &&
    !$selectedId &&
    levelsWithAvailablity.length
  )
    query({
      level: levelsWithAvailablity[0].level,
    });

  $: if ($selectedId || !$selectedId || $level || !$level) scrollTo(0, 0);
</script>

<main class="map">
  <header>
    <h1>
      <data class="property" value={property.id}>{property.name}</data>
      <address>
        <data class="city">{property.address.city}</data>
        <data class="state">{property.address.state}</data>
      </address>
    </h1>
    {#if referrer}
      <a href={referrer}>back</a>
    {/if}
    <ParkingAvailabilityLevels
      level={$level}
      {availability}
      on:change={(e) =>
        query({
          selected: null,
          ...e.detail,
        })}
    />
  </header>
  <!-- <PropertyLevelSelect
    level={$level}
    {levels}
    on:change={(e) => query("level", e.detail)}
  /> -->
  <PropertyMap
    property={property.id}
    level={$level}
    bind:levels
    selected={space?.id}
  >
    <MapFeatureState
      state={(feature) => ({
        selectable:
          !!$availableSpaces?.items[feature?.properties["ref:boss:subject"]],
        permit: $availableSpaces?.items[feature?.properties["ref:boss:subject"]]
          ? "no"
          : undefined,
        //selected: unit && unit.id == feature.properties["ref:boss:subject"],
      })}
      {filter}
    />
    {#if $hover}
      <MapFeatureHover {filter} />
    {/if}
    <MapFeatureClick
      {filter}
      on:click={(e) => {
        //console.log("map click=", e);
        $availableSpaces?.items[e.detail.properties["ref:boss:subject"]] &&
          query("selected", e.detail.properties["ref:boss:subject"]);
      }}
    />
  </PropertyMap>
  <slot />
  {#if space}
    <section>
      <header>
        <h1><RecordItem item={space} /></h1>
        <button type="button" on:click={(e) => query("selected", null)}
          >change</button
        >
      </header>
    </section>
    <!-- <SpaceForm bind:selected policy={$policy} /> -->
  {:else}
    <ParkingAvailabilitySpaces
      {base}
      {availability}
      level={$level}
      on:change={(e) =>
        query({
          level: e.detail.level,
          selected: e.detail.id,
        })}
    />
  {/if}
</main>
