<script lang="ts">
  import { onDestroy } from "svelte";
  import {
    type MapGeoJSONFeature,
    type VectorGLMap,
    type Marker,
    type LngLatLike,
    type Feature,
    pointFromFeature,
  } from "./mapping";
  import { getSvelteContext, engine } from "./mapping";

  export let feature: MapGeoJSONFeature | Feature;
  export let position: LngLatLike = null;
  let classname: string = "";
  export { classname as class };

  const { styledmap } = getSvelteContext();

  $: point = pointFromFeature(feature) ?? position;

  let element: HTMLElement;
  let marker: Marker;

  $: marker =
    element &&
    new engine.Marker({
      anchor: "bottom-left",
      className: "boss-marker",
      element,
    });

  //$: console.log("$marker=", marker, element);

  function init($map: VectorGLMap, $marker: Marker, $position: LngLatLike) {
    //console.log("init popup", $position, $content);
    if (!$map || !$position) {
      $marker?.remove();
      return;
    }
    if (!$position || !$marker) return;

    $marker.setLngLat($position);

    $marker.addTo($map);
  }

  $: init($styledmap, marker, point);

  onDestroy(function () {
    marker?.remove();
  });
</script>

<aside class="{classname} map-marker" bind:this={element}>
  <slot />
</aside>
