<script lang="ts">
  import type { Property } from "../types";
  import { thumbnail } from "../util/filepreview";

  export let property: Property;
</script>

{#if property?.logo}
  <figure class="logo">
    <img
      class="logo"
      alt="{property.name} logo"
      src={thumbnail(property.logo)}
    />
  </figure>
{/if}
