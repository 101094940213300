<script lang="ts" context="module">
  const selected = param("selected");
</script>

<script lang="ts">
  import type { Policy, Property } from "../../types";
  import { view } from "../../util/uistores";
  import PolicySpaceSection from "./PolicySpaceSection.svelte";
  import { spaces } from "../../util/propertystores";
  import { param } from "../../util/params";

  export let policy: Policy;
  //export let property: Property;
  let property = policy.property;

  $: console.log("spaces=", $spaces);

  $: base = `/policies/${policy.policy}`;
</script>

<svelte:head>
  <title>Welcome to {property.name}</title>
</svelte:head>
{#if "spaces" === $view}
  <PolicySpaceSection
    {policy}
    base={base + "/spaces"}
    referrer={base}
    space={$spaces?.[$selected]}
  />
{:else}
  policy
{/if}
