<script lang="ts">
  import type { Permit, Space, Typed, Unit } from "../../types";
  import { accountPermits, directory } from "../../util/propertystores";
  import Address from "../Address.svelte";
  import Logo from "../Logo.svelte";
  import PropertyMapPreview from "../PropertyMapPreview.svelte";
  import RecordItem from "../RecordItem.svelte";
  import Welcome from "../Welcome.svelte";

  export let unit: Unit;

  $: property = unit.property;

  $: console.log("directory=", $directory);
  $: console.log("unit=", unit);

  //$: console.log("account permits=", $accountPermits);
</script>

<svelte:head>
  <title>Welcome to {property.name}</title>
</svelte:head>

<Welcome {property} />

<section class="account">
  <Address item={unit} />
  <PropertyMapPreview
    class="unit"
    title="{unit.name} map"
    selected={unit}
    zoom={17}
  >
    <RecordItem item={unit} />
  </PropertyMapPreview>
</section>

<footer>
  <Logo {property} />
</footer>
