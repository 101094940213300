<script lang="ts">
  import type {
    Tenant,
    Permit,
    Space,
    Typed,
    Vehicle,
    Media,
  } from "../../types";
  import { accountPermits, directory } from "../../util/propertystores";
  import { comparer } from "../../util/sort";
  import Address from "../Address.svelte";
  import Level from "../Level.svelte";
  import Logo from "../Logo.svelte";
  import PropertyMapPreview from "../PropertyMapPreview.svelte";
  import RecordItem from "../RecordItem.svelte";
  import Welcome from "../Welcome.svelte";

  export let account: Tenant;

  $: property = account.property;
  $: unit = account.unit;

  $: postalFeature = $directory?.features.find(
    (f) =>
      null != f.properties["post:box"] &&
      f.properties["access"] === "permissive"
  );

  $: console.log("directory=", $directory);
  $: console.log("unit=", unit);

  //$: console.log("account permits=", $accountPermits);

  $: data = Object.values($accountPermits ?? {}).reduce(
    (
      map: {
        spaces: Record<string, Space>;
        crossref: Record<string, Record<string, Typed>>;
      },
      item: Permit
    ) => {
      if (item.continuous) {
        for (const space of Object.values(item.spaces)) {
          map.spaces[space.id] = space;
          //if (!map.crossref[space.id]) map.crossref[space.id] = {};
          for (const subject of Object.values(item.subjects)) {
            if ({ vehicle: true, media: true }[subject.type])
              (map.crossref[space.id] ??= {})[subject.id] = subject;
          }
        }
      }
      return map;
    },
    { spaces: {}, crossref: {} }
  );

  $: console.log("data=", data);
  // .filter(
  //   (permit) => permit.continuous && permit.spaces.length
  // ) as Permit[];
</script>

<svelte:head>
  <title>Welcome to {property.name}</title>
</svelte:head>

<Welcome {property} />

<section class="account">
  <Address item={unit} />
  <PropertyMapPreview
    class="unit"
    title="{unit.name} map"
    selected={unit}
    zoom={17}
  >
    <RecordItem item={unit} />
    <Level item={unit} />
  </PropertyMapPreview>

  <PropertyMapPreview
    class="unit"
    title="{unit.name} mailbox map"
    selected={postalFeature}
    zoom={17}
  >
    Mailbox {unit["post:box"]}
    {#if postalFeature?.properties}
      <Level item={postalFeature?.properties} />
    {/if}
  </PropertyMapPreview>

  <!-- <figure class="post">
    {#if postalFeature}
      <img
        alt="{account.unit.name} mailbox map"
        src="https://static.carto.communityboss.app/cdn-cgi/image/format=auto,width=600,height=300,fit=cover/?property={postalFeature
          .properties['ref:boss:property']}&selected={postalFeature.properties
          .id}&zoom=17"
      />
    {/if}
    <figcaption>
      Mailbox {account.unit["post:box"]}
      <data class="level" value={postalFeature?.properties.level}
        >{postalFeature?.properties["level:ref"]}</data
      >
    </figcaption>
  </figure> -->

  <ul>
    <li />
    {#each Object.values(data.spaces).sort(comparer("name")) as space}
      <li>
        <PropertyMapPreview
          class="unit"
          title="{space.name} map"
          selected={space}
          zoom={17}
        >
          <RecordItem item={space} />
          <Level item={postalFeature?.properties} />
          {#each Object.values(data.crossref[space.id] ?? {}) as related}
            <RecordItem item={related} />
          {/each}
        </PropertyMapPreview>
      </li>
    {/each}
  </ul>
</section>

<footer>
  <Logo {property} />
</footer>
