<script context="module" lang="ts">
  const cartoparam = param("carto");
</script>

<script lang="ts">
  import MapDisplay from "./map/MapWebGL.svelte";
  import {
    type StyleSpecification,
    type VectorGLMap,
    type MapSourceDataEvent,
    type LngLatBoundsLike,
    scalebbox,
    type Model3D,
  } from "./map/mapping";
  import { createEventDispatcher } from "svelte";
  import { param } from "../util/params";
  import { isEqual } from "lodash-es";
  // import MapModel3D from "./map/MapModel3D.svelte";
  const defaulttheme = "cool";
  export let property = null;
  export let theme = null;
  let model: Model3D;
  export let branch: string = null;
  export let builder: string = null;
  export let gltf: string = null;
  export let carto: string = null;
  export let level: string;
  export let levels: Record<string, any> | boolean = {};
  export let maxbounds: LngLatBoundsLike = null;
  export let fitbounds: LngLatBoundsLike = null;
  export let minzoom = 0;
  export let maxzoom = 22;
  export let overlay = false;
  export let selected: string[] | string = null;
  export let parking: string | boolean = true;
  export let enforcement: string = null;
  export let engine: string = null;
  export let renderer: VectorGLMap = null;

  let classname: string = "";
  export { classname as class };

  export let styledata: StyleSpecification = null;

  const eventing = createEventDispatcher();

  $: console.log("propertymap.level=", level);

  $: if (levels !== false && styledata)
    levels = styledata?.metadata?.["property:levels"] ?? {};

  $: fitbounds = styledata && checkbounds(fitbounds, styledata);
  // handle when we get an unexpected level back from the style
  $: if (null == level && null != styledata?.metadata?.["property:level"])
    changeLevel(styledata.metadata["property:level"]);

  function checkbounds(fitbounds, styledata) {
    var testbounds = scalebbox(
      styledata?.metadata?.["selection:bounds"] ? 1 : 0.25,
      styledata?.metadata?.["selection:bounds"] ??
        styledata?.metadata?.["property:bounds"]
    );
    if (!isEqual(testbounds, fitbounds)) return testbounds;
    return fitbounds;
  }

  function changeLevel(value: string) {
    if (level === value) return;
    //level = value;
    eventing("level", value);
  }

  $: if (styledata?.metadata)
    model = styledata.metadata["property:model"] as Model3D;

  //$: levels = levelsFromStyleJson(styledata);

  //$: console.log("selectable=", selectable);
  //$: console.log("styledata=", map, styledata);

  //   $: if (map && styledata && selectable)
  //     processSelection(map, selectable, selected);

  $: url = buildUrl(
    property?.id || property,
    theme || defaulttheme,
    level,
    parking,
    overlay,
    Array.isArray(selected) ? selected : [selected],
    branch,
    builder,
    carto || $cartoparam || "https://carto.communityboss.app",
    gltf
  );
  $: console.log("url=", url, "level=", level);
  // property &&
  // theme &&
  // `${carto}/v1/properties/${
  //   property?.id || property
  // }/styles/${theme}.json?level=${
  //   level ?? ""
  // }&parking=${parking.toString()}&overlay=${overlay.toString()}&selected=${
  //   selected || ""
  // }`;

  function buildUrl(
    property: string,
    theme: string,
    level: string,
    parking: string | boolean,
    overlay: boolean,
    selected: string[],
    branch: string,
    builder: string,
    carto: string,
    gltf: string
  ) {
    if (!property) return null;
    if (!theme) return null;
    const url = new URL(
      `${carto}/v1/properties/${property}/styles/${theme}.json?level=${
        level ?? ""
      }&parking=${parking.toString()}&overlay=${overlay.toString()}`
    );
    for (const item of selected || []) {
      if (item) url.searchParams.append("selected", item);
    }
    if (enforcement) url.searchParams.append("enforcement", enforcement);
    if (branch) url.searchParams.append("branch", branch);
    if (builder) url.searchParams.append("builder", builder);
    if (gltf) url.searchParams.append("gltf", gltf);
    return url.toString();
  }
</script>

<MapDisplay
  class={classname}
  style={url}
  bind:styledata
  bind:MAP={renderer}
  {maxbounds}
  {fitbounds}
  {minzoom}
  {maxzoom}
  {engine}
>
  <slot />
  <!-- {#if model}
    <MapModel3D {model} />
  {/if} -->
  <figcaption slot="contents" />
</MapDisplay>
