<script lang="ts">
  import type { Feature } from "geojson";
  let classname: string = "";
  export { classname as class };
  export let feature: Feature = null;
  let element: HTMLDataElement;

  $: if (null != element) {
    element.setAttribute("data-type", feature.type);
    for (const attr of element.getAttributeNames()) {
      if (attr.startsWith("data-")) element.removeAttribute(attr);
    }
    for (let [key, value] of Object.entries(feature.properties)) {
      if (key == "image") continue;
      if (key == "url") continue;

      element.setAttribute("data-" + key.replace(/[^0-9a-z]/gi, "-"), value);
    }
  }
</script>

<data class="{classname} feature point" bind:this={element}>
  <slot />
</data>
